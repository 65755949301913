import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { PUBLIC_API_URL } from '../constants/index';
import * as OrderServices from '../services/orderService';

const OrderDetailList = ({
  orderView,
  handleOrderHideUser,
  orderData,
  usersData,
  colorId,
}) => {
  const shippingRate = orderData?.userDetails?.userShippingRates;
  const totalProduct = orderData?.productDetails?.length;
  const [frontFlatImage, setFrontFlatImage] = useState({});
  const [backFlatImage, setBackFlatImage] = useState({});

  // const fetchCartProducts = async (colorId) => {
  //   try {
  //     if (usersData) {
  //       const promises = usersData.map(async (item) => {
  //         const productDetails = item?.orderId?.productDetails?.map(
  //           async (products) => {
  //             try {
  //               const data = await OrderServices.getDataByVariantId(colorId);

  //               const productDetails = data?.images?.data?.images;
  //               if (productDetails) {
  //                 const filterImagesByOption = (data, option, optionGroup) =>
  //                   data?.filter(
  //                     (item) =>
  //                       item.option === option &&
  //                       item.option_group === optionGroup,
  //                   );

  //                 let frontDefaultImage = filterImagesByOption(
  //                   productDetails,
  //                   'Front',
  //                   'Default',
  //                 );
  //                 let frontFlatImage = filterImagesByOption(
  //                   productDetails,
  //                   'Front',
  //                   'Flat',
  //                 );
  //                 let frontFlatImage2 = filterImagesByOption(
  //                   productDetails,
  //                   'Front Outside',
  //                   'Flat',
  //                 );
  //                 let frontGhostImage = filterImagesByOption(
  //                   productDetails,
  //                   'Front',
  //                   'Ghost',
  //                 );

  //                 let backDefaultImage = filterImagesByOption(
  //                   productDetails,
  //                   'Back',
  //                   'Default',
  //                 );
  //                 let backFlatImage = filterImagesByOption(
  //                   productDetails,
  //                   'Back',
  //                   'Flat',
  //                 );
  //                 let backFlatImage2 = filterImagesByOption(
  //                   productDetails,
  //                   'Back Outside',
  //                   'Flat',
  //                 );
  //                 let backGhostImage = filterImagesByOption(
  //                   productDetails,
  //                   'Back',
  //                   'Ghost',
  //                 );

  //                 const getImageUrl = (imagesArray) =>
  //                   imagesArray && imagesArray.length > 0
  //                     ? imagesArray[0]?.image_url
  //                     : null;

  //                 const frontImage =
  //                   getImageUrl(frontDefaultImage) ||
  //                   getImageUrl(frontFlatImage) ||
  //                   getImageUrl(frontFlatImage2) ||
  //                   getImageUrl(frontGhostImage);

  //                 const backImage =
  //                   getImageUrl(backDefaultImage) ||
  //                   getImageUrl(backFlatImage) ||
  //                   getImageUrl(backFlatImage2) ||
  //                   getImageUrl(backGhostImage);

  //                 setFrontFlatImage(frontImage);
  //                 setBackFlatImage(backImage);
  //               }
  //             } catch (error) {
  //               console.error('Error fetching product details:', error);
  //             }
  //           },
  //         );
  //         await Promise.all(productDetails);
  //       });
  //       await Promise.all(promises);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching order images:', error);
  //   }
  // };

  const fetchCartProducts = async (colorId) => {
    try {
      if (!usersData) return;

      const fetchProductDetails = async (item) => {
        try {
          const data = await OrderServices.getDataByVariantId(colorId);
          const productDetails = data?.images?.data?.images;
          if (productDetails) {
            const filterImagesByOption = (option, optionGroup) =>
              productDetails.filter(
                (item) =>
                  item.option === option && item.option_group === optionGroup,
              );

            const getImageUrl = (imagesArray) =>
              imagesArray && imagesArray.length > 0
                ? imagesArray[0]?.image_url
                : null;

            const frontImages = [
              filterImagesByOption('Front', 'Default'),
              filterImagesByOption('Front', 'Flat'),
              filterImagesByOption('Front Outside', 'Flat'),
              filterImagesByOption('Front', 'Ghost'),
            ];
            const backImages = [
              filterImagesByOption('Back', 'Default'),
              filterImagesByOption('Back', 'Flat'),
              filterImagesByOption('Back Outside', 'Flat'),
              filterImagesByOption('Back', 'Ghost'),
            ];

            const frontImage = frontImages.map(getImageUrl).find((url) => url);
            const backImage = backImages.map(getImageUrl).find((url) => url);

            setFrontFlatImage(frontImage);
            setBackFlatImage(backImage);
          }
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      };

      await Promise.all(usersData.map(fetchProductDetails));
    } catch (error) {
      console.error('Error fetching order images:', error);
    }
  };

  useEffect(() => {
    fetchCartProducts(colorId);
  }, [usersData, colorId]);

  const getAddressDisplay = () => {
    const { userAddress, userCity, userCountry } = orderData.userDetails;
    return `${userAddress} / ${userCity} / ${userCountry}`;
  };

  const calculateTotalProfit = (quantity, marginValue, actualPrice) => {
    if (marginValue && marginValue.marginType == '$') {
      return (
        parseFloat(quantity) * parseFloat(marginValue.marginValue)
      ).toFixed(2);
    } else if (marginValue) {
      return (
        parseFloat(quantity) *
        (parseFloat(marginValue.marginValue) / 100) *
        parseFloat(actualPrice)
      ).toFixed(2);
    }
    return '0.00';
  };

  const renderProductRow = (product, index) => {
    const {
      productName,
      productQuantity,
      productActualPrice,
      productTotalPrice,
      productPrice,
    } = product;
    let productMargin = product.productMargin;

    const frontImageSrc = product.productFrontImage
      ? `${PUBLIC_API_URL}/uploads/${product.productFrontImage}`
      : frontFlatImage || '';

    const backImageSource = product.productBackImage
      ? `${PUBLIC_API_URL}/uploads/${product.productBackImage}`
      : backFlatImage || '';

    const totalProductPrice = productMargin
      ? productMargin.marginType === '$'
        ? (
            parseFloat(productActualPrice) +
            parseFloat(productMargin.marginValue)
          ).toFixed(2)
        : (
            parseFloat(productActualPrice) *
            (1 + parseFloat(productMargin.marginValue) / 100)
          ).toFixed(2)
      : parseFloat(productActualPrice);

    const totalProfit = calculateTotalProfit(
      productQuantity,
      productMargin,
      productActualPrice,
    );

    return (
      <tr key={index}>
        <td>{orderData.userOrderId}</td>
        <td>{productName}</td>
        <td>$ {productActualPrice ? `${productActualPrice}` : '0.00'}</td>
        <td>
          {productMargin
            ? productMargin.marginType == '$'
              ? `${productMargin.marginType}${productMargin.marginValue}`
              : `${productMargin.marginValue}${productMargin.marginType}`
            : '0.00'}
        </td>
        <td>$ {totalProductPrice ? `${totalProductPrice}` : '0.00'}</td>
        <td>{productQuantity}</td>
        <td>$ {productTotalPrice ? productTotalPrice : productPrice}</td>
        <td>$ {totalProfit ? `${totalProfit}` : '0.00'}</td>
        <td>
          <img src={frontImageSrc} alt="Loading..." />
        </td>
        <td>
          {backImageSource ? (
            <img
              className="img-fluid"
              src={backImageSource}
              alt="Loading..."
              width="150"
              height="130"
            />
          ) : (
            'No image'
          )}
        </td>
        <td>{orderData.userDetails.userFullName}</td>
        <td>{getAddressDisplay()}</td>
        {index === 0 && <td rowSpan={totalProduct}>$ {shippingRate}</td>}
      </tr>
    );
  };

  const renderProductDetails = () => {
    return orderData?.productDetails?.map((product, index) => {
      return renderProductRow(product, index);
    });
  };
  return (
    <Modal
      show={orderView}
      className="user-modal order-detail-modal"
      onHide={handleOrderHideUser}
    >
      <div className="login1">
        <Modal.Header>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Product Name</th>
                <th>Printful Product Price</th>
                <th>Margin Value</th>
                <th>Product Price</th>
                <th>Product Quantity</th>
                <th>Product Total Price</th>
                <th>Total Profit</th>
                <th>Front Image</th>
                <th>Back Image</th>
                <th>Order By</th>
                <th>Shipping Address</th>
                <th>Shipping Rate</th>
              </tr>
            </thead>
            <tbody>{renderProductDetails()}</tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleOrderHideUser}>
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default OrderDetailList;
